/* ==========================================================================
	Get Started STYLES
========================================================================== */

.cta {
	background-color:$color-base;
	color:$white;
	@include remit(margin-top, $g-spacing/2);
	@include remit(margin-bottom, $g-spacing);

	&__title {
		@include font-size(30, $hsu, 30*1.2);
	}

	.icon--title {
		width:70px;
		height:70px;
	}
}

.ctaGetStarted {
	background-color:$color-sub;
	border:6px solid $white;
	position:relative;
	@include remit(top, -40);
	@include remit(padding, 30);
	@include remit(margin-bottom, -40);


	@include mq($regular) {
		@include span(10);
		@include remit(margin-bottom, -80);
	}

	a {
		color:$white;
	}

	p, &__contacts, &__header {
		@include remit(margin-bottom, 30);
	}


	&__header {
		display: flex;
		align-items: center;
	}

	&__title {
		width: 100%;

		.cta__title {
			margin-bottom:0;
		}
	}

	&__icon {
		@include remit(margin-right, 24);
		width:70px;
	}

	&__contacts {
		display: flex;
		flex-flow: row wrap;
	}

	&__contact {
		@include remit(margin-right, $bsu);
		@include remit(margin-bottom, $hsu);
	}
}


.ctaMessage {
	@extend %cf;
	display: flex;
	@include remit(padding-top, 30);
	@include remit(padding-bottom, 30);

	@include mq($regular) {
		@include span(14 last);
	}

	&__content {
		width: 100%;
		@include remit(padding-top, 10);
	}

	&__icon {
		@include remit(margin-right, 24);
		width:70px;
	}

	p {
		@include font-size(16, yes, 16*1.8);
		font-weight:600;
	}

}