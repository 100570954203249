/* ==========================================================================
	HIGHLIGHT TEXT STYLES
========================================================================== */

.highlightText {
	color:$color-base;
	border-left:5px solid $color-base;
	margin-left:10%;
	margin-right:10%;
	margin-top:5%;
	margin-bottom:5%;

	.highlightText__text {
		font-weight:300;
		font-style:italic;
		@include font-size(18, no, 18*1.5);
		@include remit(padding, 10 0 10 $hsu);
		color:$color-base;
	}
}

.highlightText {

	&--right {
		@include mq($medium) {
			@include span(12 last);
			margin-left:5%;
			margin-right:0;
		}
	}

	&--left {
		@include mq($medium) {
			@include span(12);
			margin-left:5%;
		}
	}
}