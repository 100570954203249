/* ==========================================================================
	BLOG TEASER STYLES
========================================================================== */

.teaserBlog {
	color:$color-quad;

	&__title {
		@include font-size(18);
		font-weight:600;
		font-family:$font-body;

		a {
			color:$color-quad;

			@include hover() {
				color:$color-link;
			}
		}
	}

	.date {
		@include remit(margin-bottom, 6);
	}

	p:last-of-type() {margin-bottom:0;}
}


.teaserBlog {

	&--half {
		@include mq($regular) {
			@include gallery(12);
			@include pad(0.5);
		}
	}

	&--in-list {
		@include remit(padding-top, $hsu);
		@include remit(padding-bottom, $hsu);
		@include remit(margin-bottom, $bsu*2.5);

		@include mq($regular) {
			border-image: linear-gradient(to bottom, rgba($color-quad, 0.5), rgba(0, 0, 0, 0)) 1 100%;
			border-left:5px solid rgba($color-quad, 0.5);
		}
	}

}