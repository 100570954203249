/* ==========================================================================
	CONTACT STYLES
========================================================================== */

.tel {

	@include font-size(24);
	white-space: nowrap;

	// .header__call & {
	// 	color:$color-tri;
	// 	@extend %alpha;
	// 	line-height:1;
	// }

	.header__tel & {
		color:$white;
		font-weight:600;
		@include font-rem(24);
		line-height:1;

		@include mq($regular) {
			@include font-rem(30);
		}
	}

	.ctaSimple & {
		color:currentColor;
	}
}


.email {
	@include font-size(18);
	white-space: nowrap;

	.footer & {
		@include remit(margin-right, $bsu);
	}

	.ctaSimple & {
		color:currentColor;
	}
}


.office {
	&__title {
		@include font-size(16, $hsu);
		font-weight:600;
		@include remit(padding-bottom, 6);
		border-bottom:1px solid rgba($color-text, 0.3);

		.footer & {
			@include font-size(14, $hsu);
			border-bottom:1px solid rgba($white, 0.3);
		}
	}
}



.address {
	@include font-size(16);

	.footer & {
		@include font-size($footer-font-size);
	}
}


.contact {

	&__item {
		@include remit(margin-top, $bsu);
		@include remit(margin-bottom, $hsu);
		@include suffix(1);

		@include mq($small) {
			@include gallery(12, of-type);
			@include remit(margin-bottom, $bsu);
		}
	}
}

.contact-form {
	@extend %no-gutter;
	// @include gradient(#49a194, #39858b, vertical);
	background-color:lighten($lightgrey, 10%);
	@include remit(padding, $bsu);
}

