/* ==========================================================================
	FEATURE STYLES
========================================================================== */

.feature {
	color:$white;
	@include remit(padding, 30);
	@extend %no-gutter;

	&__header {
		display: flex;
		flex-flow: row;
		align-items: center;
		@include remit(margin-bottom, $hsu);
	}

	&__title {
		margin-bottom:0;
		width:100%;
	}

	&__icon {
		@include remit(margin-right, 20);

		.icon {
			width:60px;
			height:60px;
		}
	}
}