/* ==========================================================================
	HOME STYLES
========================================================================== */

.homeBanner__content {
	display:none;

	@include mq($medium) {
		display:block;
	}
}


.heroBannerNav--home {
	display:none;

	@include mq($medium) {
		display:block;
	}
}

.slickServices--home {
	display:block;
	@include remit(margin-top, -5); //fix to remove white space

	@include mq($medium) {
		display:none;
	}
}











