/* ==========================================================================
	BASE FORM STYLES
========================================================================== */

//Give form elements some cursor interactions...
label,
input,
textarea,
button,
select,
option,
{
	cursor:pointer;
}
	%text-input:active,
	%text-input:focus,
	textarea:active,
	textarea:focus{
		cursor:text;
		outline:none;
	}

%text-input {
	color: $color-text;
	border:1px solid #dedede;
	border-radius:0;
	outline:none;
	-webkit-appearance: none;

	&:focus {
		@include mq($regular, false, max) {
			@include font-rem(16);
		}
	}
}

select {
	background-image:url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2219%22%20height%3D%229%22%20viewBox%3D%220%200%2019%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eselect%3C%2Ftitle%3E%3Cg%20id%3D%22Icons-export%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22select%22%20fill%3D%22%23B4B4B4%22%3E%3Cpath%20d%3D%22M19%200L9.5%209%200%200h19z%22%20id%3D%22Rectangle-74%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
	background-repeat:no-repeat;
	background-position:right 10px center;
	background-color:$white;
}

#fax {
	@extend .hidden;
}

/* ==========================================================================
	FILE INPUT STYLES
========================================================================== */
.js .inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  max-width: 80%;
}

.inputfile + label {
	max-width: 80%;
	// @include font-rem(18);
	font-size:14px !important;
	/* 20px */
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	padding: 0.625rem 0 0;
	/* 10px 20px */
}

.no-js .inputfile + label {
	display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	fill: currentColor;
	margin-top: -0.25em;
	/* 4px */
	margin-right: 0.25em;
	margin-left: 0.25em;
	/* 4px */
}


.inputfile-3 + label {
	color: $white;
}

.inputfile-3:focus + label,
.inputfile-3.has-focus + label,
.inputfile-3 + label:hover {
	color: $color-tri;
}
/* ==========================================================================
	CHECKBOX STYLES
========================================================================== */

.checkbox {
	position:relative;
	border:1px solid $color-base;
	float:left;
	@include remit(margin-right, 6);
	@include remit(margin-bottom, $hsu);
	width:90px;
	text-align:center;

	span {
		display:block;
		@include remit(padding, 6 10);
		transition:$g-trans;

		@include hover {
			color:$white;
		}
	}

	input {
		position:absolute;
		opacity:0;
		z-index:-1;
		width:100%;
	}

	input:checked + span {
		background-color:$color-base;
		color:$white;
	}

	span:hover, input:checked + span {
		background-color: $color-sub;
	}
}

/* ==========================================================================
	ALL FORM STYLES
========================================================================== */

.required {
	color:$color-sub;

	&:before {
		content: "*";
		margin-right: 3px;
		color:currentColor;
	}

	.form--cvReview &, .form--getStarted & {
		color:$color-tri;
	}
}

.submit {
	@extend %btn;
	position:relative;
	border-radius:0;
}

.instructions {
	@include font-size(11, $hsu, 16);
	font-style:italic;
}


.form {
	label {
		@include font-size(13);
		font-weight:600;
	}

	input[type="text"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	select,
	textarea {
		@extend %text-input;
		@include placeholder(lighten($color-text, 30%), 0.8, 14);

		&:focus, &.required:focus {
			@include placeholder($white, 0.6, 14);
			border:1px solid rgba($white, .5);
			background-color: $color-sub;
			color:$white;
			outline: none;
		}

		&.required {
			font-weight:400;
		}

		&.has-error {
			border: 1px solid $color-error;
			background-color: $bg-error;
			color: $color-error;
			@include placeholder($color-error)
		}
	}

	input[type="search"] {
		@include remit(margin-bottom, $bsu);
	}

	&__row {
		@extend %cf;
	}

	 &__item {
		@include remit(margin-bottom, $bsu);

		$cols: 6;
		@for $i from 1 through $cols {
			&--#{24/$i} {
				@include mq($large) {
					@include gallery(24/$i);
				}
			}
		}
	}
}

/* ==========================================================================
	USER INPUT FORM STYLES
========================================================================== */

.form--userInput {
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="search"],
	input[type="file"],
	select,
	textarea {
		@include remit(height, 45);
		@include remit(padding, 8 6);
		width:100%;
	}

	textarea {
		height:auto;
	}

	input[type="text"]:disabled {
		background: transparent;
	}
}

/* ==========================================================================
	CV Review Styles
========================================================================== */

.form--cvReview {
	input[type="file"] {
		@include font-rem(12);
	}
}

// .inputfile {
// 	width: 0.1px;
// 	height: 0.1px;
// 	opacity: 0;
// 	overflow: hidden;
// 	position: absolute;
// 	z-index: -1;
// }


// .inputfile + label {
// 	font-size: 1.25em;
// 	font-weight: 700;
// 	color: white;
// 	background-color: black;
// 	display: inline-block;
// }

// .inputfile:focus + label,
// .inputfile + label:hover {
// 	 background-color: red;
// }

// .inputfile + label {
// 	cursor: pointer; /* "hand" cursor */
// }

// .inputfile:focus + label {
// 	outline: 1px dotted #000;
// 	outline: -webkit-focus-ring-color auto 5px;
// }

/* ==========================================================================
   HEADER SEARCH FORM STYLES
========================================================================== */

.form--searchHeader {
	font-size:0;

	p {
		display:inline-block;
	}

	input {
		width:70%;
		display:inline-block;
		vertical-align: top;
		@include remit(height, 40);
		@include remit(padding, 10);
		@include font-rem(16);
	}

	button {
		display:inline-block;
		vertical-align: top;
		@include remit(height, 62);
		min-width:auto;

		@include hover() {
			background-color:$color-tri;
		}
	}
}

/* ==========================================================================
	ERROR STYLES
========================================================================== */

.has-error {
	margin-bottom:0;
	color: $color-error;
	font-weight:bold;
	@include shake($delay:0.6);

	.form--cvReview & {
		color:$white;
	}

	.form--getStarted & {
		color:$black
	}
}

span.has-error {
	@include font-size(13);
}

input.has-error {
	@include remit(margin-bottom, 4);
}


/* ==========================================================================
	REQUIRED STYLES
========================================================================== */

// .is-required {
// 	color:$color-sub !important;
// }