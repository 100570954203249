/* ==========================================================================
	CONTENT STYLES
========================================================================== */

.content {

	&--left {
		@include mq($regular) {
			@include span(13);
		}
	}

	&--right {
		@include mq($regular) {
			@include span(10 last);
		}
	}
}


.blog {
	&--left {
		@include mq($regular) {
			@include span(13);
		}

		@include mq($large) {
			@include pre(0.5);
			@include span(13.5);
		}
	}

	&--right {
		@include mq($regular) {
			@include span(10 last);
		}

		@include mq($large) {
			@include span(8.5 last);
			@include post(0.5);
		}
	}
}

.about {
  &--left {
    @include mq($regular) {
      @include pre(0.5);
      @include span(13);
    }
  }

  &--right {
    @include mq($regular) {
      @include post(0.5);
      @include span(9 last);
    }
  }
}
