/* ==========================================================================
	HEADING STYLES
========================================================================== */

h1, h2, h3, h4, h5, h6 {
	// color:$color-text-header;
	font-family:$font-header;
	text-rendering: optimizeLegibility;
	font-weight:400;
}

h1, .alpha 		{
	@include font-size($h1-font-size);
}


h2, .beta {
	@include font-size($h2-font-size);
}

h3, .gamma	{
	@include font-size($h3-font-size);
}


h4, .delta {
	@include font-size($h4-font-size);
}

h5, .epsilon {
	@include font-size($h5-font-size);
}

h6, .zeta {
	@include font-size($h6-font-size);
}

// .block-title {
// 	@include remit(padding, $hsu $bsu);
// 	background-color:$color-base;
// 	color:$white;

// 	a {
// 		color:$white;
// 	}
// }

.date {
	@include font-rem(14);
	color:$black;
	font-style:italic;
}

.price {
	color: $color-base;
	@include font-size(18);
	font-weight:600;
}

.blog-title {
	color:$black;
	max-width:950px;
	@include remit(margin-bottom, 6);
}

/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */


/* List Style */
ul.square	{list-style: square inside;}
ul.disc 	{list-style: circle inside;}
ul.bullet 	{list-style: disc inside;}

// Remove vertical spacing from nested lists.
li {
	> ul,
	> ol{
		 margin-bottom:0;
	 }
}


.teaser-copy {
	p {
		color:currentColor;
		@include font-size(14);
	}

	strong {
		@include font-size(16);
	}

	ul 	{list-style: disc inside;}

	li:not([class])	{
		@include font-size(14, no, 21);
		@include remit(margin, $hsu);
	}
}

// This styles the main content of the page
// For other content sections and to change styling on paragraphs and headings and chunks create new blocks like side-bar etc
.title-spacing {
	@include remit(margin-top, $g-spacing/2);
}

.main-copy {

	h1,h2,h3 {
		color:$black;
	}

	p {
		color:$color-text;
		@include font-size($p-font-size);
	}

	ul, ol {
		@include remit(margin-bottom, $bsu);
		color:$color-text;
	}

	ul 	{list-style: disc inside;}

	li	{
		@include font-size($p-font-size, no);
		@include remit(margin, $hsu);
	}

	ol {
		counter-reset: section;
		list-style-type: none;
		@include remit(padding-top, $hsu);

		li {
			position:relative;
		// display:flex;
		// align-items: flex-start;
			@include remit(padding-top, $bsu);
			@include remit(padding-bottom, $bsu+$hsu);
			@include remit(margin-left, $bsu);
			@include remit(padding-left, 30);
			margin-bottom:0;
			margin-top:0;

			border-left: 5px solid $turquoise;

			&:last-of-type {
				@include remit(padding-bottom, $bsu);
			}

			&:first-of-type {
				@include remit(padding-top, $bsu+$hsu);
			}

			&::before {
				counter-increment: section; //Increment the value of section counter by 1
				content: counters(section,".") " ";
				text-align:center;
				display: inline-block;
				@include remit(margin-top, -5);
				// @include remit(margin-right, 16);
				// @include remit(margin-left, -21 - 30);
				position:absolute;
				@include remit(left, -21);
				@include remit(width, 35);
				@include remit(height, 35);
				@include remit(line-height, 35);
				@include font-rem(16);
				border-radius:50%;
				background-color:$turquoise;
				color:$white;
			}
		}
	}

}


.lead p {
	color:$color-base;
	@include font-size($p-font-size * 1.125, yes, ($p-font-size * 1.125)*1.5);
}


.footer {
	p {
		@include font-size($footer-font-size);
	}
}


// .bullet-cols {
// 	// number of cols, gap between in rems
// 	// TODO: improve this mixin to include PXs and base unit as PXs and modularise it
// 	@include cols(2, 1);
// }


/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */

%caps 				{text-transform:uppercase;}

// .extra-light 		{font-weight:100;}
// .light 				{font-weight:300;}
// .normal 			{font-weight:400;}
// .bold			 	{font-weight:700;}
// .extra-bold 		{font-weight:900;}


// .xsmall 			{@include font-size(11);}
// .small 				{font-size:80% !important;}
// .medium 			{font-size:110% !important;}
.large 				{font-size:130% !important;}
// .xlarge 			{@include font-size(30);}