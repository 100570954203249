/* ==========================================================================
   FOOTER STYLES
========================================================================== */

.footer {
	@extend %cf;
	width:100%;
	position:relative;
	@include gradient(#49a194, #39858b, vertical);
	@include remit(padding-top,$bsu);
}


// .footer {



// 	&__legals {
// 	}

// 	&__social {
// 	}

// }


.footerContact {
	@extend %cf;
	color:$white;

	@include mq($regular) {
		@include span(12);
	}

	&__devices {
		width:100%;
		@include remit(margin-bottom, $bsu);
	}

	&__offices {
		@extend %cf;
	}

	&__item {
		@include remit(margin-bottom, $hsu);
		@include suffix(1);

		@include mq($small) {
			@include gallery(12, of-type);
			@include remit(margin-bottom, $bsu);
		}
	}

	&__social {
		clear:both;
	}
}


.footerNav {
	// display:none;
	text-align:center;

	@include mq($regular) {
		@include span(12 last);
	}

	&__logo {
		max-width:220px;
		margin:auto;
		@include remit(margin-bottom, $bsu);
	}

	&__nav {
		@extend %cf;
		@include remit(margin-bottom, $bsu);
	}
}



.footerEnd {
	text-align:center;
	background-color:$white;
	color:$color-text;
	@include remit(padding-top, $hsu);
	@include remit(margin-bottom, -$bsu, !important);
	overflow:hidden;

	@include mq($regular) {
		text-align:left;
	}
}

.footerPayment {
	@include mq($regular) {
		float:right;
	}
}

.footerLegals {
	@include mq($regular) {
		float:left;
	}
}