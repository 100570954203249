/* ==========================================================================
	CLIENT LOGOS STYLES
========================================================================== */

.clientLogos {
  @extend %cf;

  &__item {
    list-style:none;
  }

  &__logo {
    @include remit(margin, $hsu);
    @include remit(padding, $bsu);
    @extend %shadow;

    .no-js & {
      @include span(11);
    }
  }

}