/* ==========================================================================
	SERVICE TEASER STYLES
========================================================================== */
$gallery: (gutters: 0);

.slickServices {
	@extend %cf;
}

.teaserService {
	text-align:center;
	color:$white;
	transition:$g-trans;
	height:230px;
	position: relative;
	@include remit(margin-bottom, -5); //fix to remove white space

  @include with-layout($gallery) {
    .no-js & {
      @include gallery(12)

      @include mq($regular) {
        @include gallery(6)
      }
    }
  }

	a {
		display:block;
		height:100%;
		width:100%;
		transform-style: preserve-3d;
		color:$white;
	}

	&:after {
		transition: $g-trans;
		content: '';
		position:absolute;
		bottom:0;
		left:0;
		right:0;
		display:block;
		border-bottom:0 solid rgba($black, 0.2);
	}

	@include hover() {
		cursor:pointer;
		transition:$g-trans;

		.teaserService__label {
			@include remit(margin-top, -10);
		}

		&:after {
			border-bottom:20px solid rgba($black, 0.2);
		}
	}

	&__label {
		position: absolute;
		top:25%;
		left:0;
		right:0;

		transition:$g-trans;
		text-align:center;
	}

	&__title {
		font-family: $font-header;
		@include font-rem(24);
		@include remit(padding-left, $hsu);
		@include remit(padding-right, $hsu);

		@include mq($regular) {
			@include font-rem(26);
		}
	}

	.icon {
		width:100%;
		height:50px;
		@include remit(margin-bottom, 6);

		@include mq($regular) {
			height:70px;
		}
	}
}
