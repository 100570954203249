/* ==========================================================================
	COUNTER STYLES
========================================================================== */
.counter {
	counter-reset: section;
	list-style-type: none;

	li {
		position:relative;

		&::before {
			counter-increment: section; //Increment the value of section counter by 1
			content: counters(section,".") " ";
		}
	}
}

.counter__item::before {
	text-align:center;
	line-height:1;
	display: inline-block;
	float:right;
	@include remit(margin-left, 20);
	@include remit(width, 70);
	@include remit(height, 70);
	@include font-size(21, 20, 70);
	border-radius:50%;
	background-color:rgba($white, 0.3);
}
