/* ==========================================================================
	CAPTION ICON STYLES
========================================================================== */


.captionIcon {
	color:$white;
	@include remit(padding, 6 $hsu);

	.icon {
		width:20px;
		height:20px;
		@include remit(top,5);
	}

	&__title {
		display:inline-block;
		@include remit(margin-left, 4);
		@include font-size(16, no, 14);
	}

	a {
		color:$white;
	}
}



.captionIcon {

	&--header {
		position:absolute;
		// @include remit(top, -36);
		@include remit(top, $hsu);
		@include remit(left, $hsu);

		@include mq($medium) {
			display:none;
		}
	}

	&--blog {
		display:inline-block;
		position:relative;
		@include remit(left, -$hsu);

		@include remit(margin-bottom, 18);

		@include mq($small) {
			@include remit(left, -$bsu);
		}

	}

}