/* ==========================================================================
	USP  STYLES
========================================================================== */


//USP PAGE  STYLES
//========================================================================== //

$colors: ($green,#09aaad,#068463,#7bccb7,#279aa7,#66cad5,#7ab4b9);

.usp-list {
	display:flex;
	flex-wrap: wrap;
	@include remit(margin-bottom, $g-spacing);

	&__item {
		list-style:none;
		color:$white;
		@include remit(padding, 36 $bsu);


		@for $i from 1 through length($colors) {
			&:nth-of-type(#{$i}n) {
				background-color: nth($colors, $i);
			}
		}

		@include mq($small) {
			width:50%;
		}

		@include mq($regular) {
			width:33.3333333%;
		}

		&:last-of-type {
			flex-grow:1;
		}
	}



	&__title {
		@include font-size(21, $hsu,21*1.5);
		font-weight:600;
		border-bottom:1px solid rgba($white, 0.3);
		@include remit(padding-bottom, $hsu);
		@include remit(margin-right, 85);
	}

	p {
		@include font-size($p-font-size);
		font-style:italic;
		// @include remit(padding, 0 10);
	}

	.icon {
		float:right;
		@include remit(margin-left, $hsu);
		@include remit(margin-bottom, $hsu);
		width:100px;
	}
}




//USP SIDEBAR  STYLES
//========================================================================== //

.usp-sidebar {
  summary::-webkit-details-marker { display:none; }

	@include remit(margin-top, $bsu);

	&__item {
		transition:$g-trans;
		position:relative;
		@include remit(margin-bottom, 20);
		@include remit(margin-left, 20);
		@include font-rem(17);
		list-style:none;
    cursor:pointer;

		// @include hover() {
		// 	transform: translateX(10px);
		// }
	}

	&__link {
		color:$white;
    transition:$g-trans;

		@include hover() {
			color:$color-tri;
		}

    &:after {
      transition:$g-trans;
      content:'Read more';
      color:rgba($white, 0.5);
      display:inline-block;
      @include font-rem(11);
      // @extend %caps;
      @include remit(margin-left,4);
      @include remit(padding-left,6);
      border-left:1px solid currentColor;
    }

     &.is-active {
      @include remit(padding-top, $hsu);
      color:$color-tri;
      transform: translateX(10px);
      &:after {
        content:'x Close';
      }
    }
	}

  &__copy {
    p:last-of-type {
      margin-bottom:0;
    }
  }

	.icon {
		width:18px;
		height:18px;
		@include remit(margin-right, 3);
		@include remit(top, 2);
	}
}