/* ==========================================================================
	PAGINATION STYLES
========================================================================== */

.pagination {
	@extend %cf;
	position:relative;
	z-index:200;
	clear:both;
	@include remit(margin-bottom, $bsu);

	.tab & {
		margin-bottom:0;
	}
}

.pagination__page {
	@include remit(margin-right, $hsu);
	@include remit(margin-left, $hsu);
	@include font-rem(14);
}

.pagination__link {
	@include font-rem(14);
	position:relative;
}



.page-links {
	list-style:none;
	font-size:0;
	@include remit(margin-bottom, $bsu);

	li {
		transition:$g-trans;
		border:1px solid;
		border-color:$lightgrey;
		text-align:center;
		display:inline-block;
		@include font-rem(13);
		@include remit(margin, 6 3);
		@include remit(width,  26);
		@include remit(height,  26);
		@include remit(line-height, 26);
		color:#666;

		.tab & {
			color:$white;
			border-color:rgba($white, 0.5);
		}

		a {
			display:block;
			color:currentColor;

			@include hover() {
				background-color:$color-hover;
				color:$white;
			}
		}

		&.disabled {
			color:#b6b6b6;
			background-color:#e3e3e3;

			.tab & {
				border-color:darken($color-base, 10%);
				color:darken($color-base, 20%);
				background-color:darken($color-base, 10%);
			}
		}
	}

	.current {
		color:$white;
		background-color:$color-base;
		border-color:$color-base;
	}


}