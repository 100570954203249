/* ==========================================================================
	HEADER STYLES
========================================================================== */
.header {
	@extend %cf;
	@include gradient(#49a194, #39858b, vertical);
	@extend %padding-container;

	@include mq($nav-bp) {
		@include remit(padding-top, 16);
	}
}

.header {

	&__top {
		@extend %cf;
	}


	&__tel {
		text-align:center;
		@include remit(margin-bottom, $bsu);
		@include fadeInUp($duration:0.5s, $delay:1s, $fill:both);

		@include mq($nav-bp) {
			@include span(6 last);
			text-align:right;
			margin-bottom:0;
		}

	}

	&__nav {
		@include remit(margin-bottom, $bsu);

		@include mq($nav-bp) {
			@include span(18);
			@include remit(margin-bottom, 16);
		}


	}

	&__logo {
		width:span(12);
		@include remit(margin-bottom, 6);
		display:inline-block;

		@include mq($nav-bp) {
			display:none;
		}

		img {
			max-width:150px;
		}
	}
}


/* ==========================================================================
	HEADER NAV STYLES
========================================================================== */

.header__top {
	display:none;

	@include mq($nav-bp) {
		display:block;
	}

	&.is-active {
		clear:both;
		display:block;
		@include remit(padding-top, $bsu);
		@include fadeInDown();
	}
}