@keyframes pulse {
	100% { box-shadow: 0 0 0 2em rgba(#fff, 0)}
}

// /* ==========================================================================
// 	HEADER ANIMATIONS
// ========================================================================== */

$menuItems: 10;

%animate-nav-items {
	@for $i from 1 through $menuItems {
		&:nth-of-type(#{$i}n) {
			transform:translateY(($i*-200px));
			animation-delay:(($i*0.05s));;
		}
	}
}

// .logo {
// 	@include slideInLeft($delay:1s);

// 	@include mq($nav-bp) {
// 		@include slideInRight($delay:1s);
// 	}
// }

.slick-current, .banner--page {
	.heroBanner__icon {
		@include bounceInDown();
	}

	.heroBanner__copy {
		@include fadeInUp($delay:1s);
	}

	.heroBanner__btn {
		&:nth-of-type(1) {
			@include fadeInUp($delay:1.5s);
		}
		&:nth-of-type(2) {
			@include fadeInUp($delay:2s);
		}
	}

	.heroBanner__caption {
		@include slideInLeft($delay:0.5s);
	}
}

