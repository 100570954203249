/* ==========================================================================
	TERM STYLES
========================================================================== */

.term {
	@extend %cf;
	border-bottom:1px solid $lightgrey;
	@include remit(margin-bottom, $bsu);

	&:last-of-type {
		border:0;
	}
}


.terms--primary {
  @include mq($regular) {
    @include span(14);
  }
}

.terms--secondary {
  @include mq($regular) {
    @include span(8 last);
  }
}