/* ==========================================================================
	BUTTON STYLES
========================================================================== */

.btns {
	@include remit(padding-top, $hsu);

	.btn {
		@include remit(margin-right, 6);
		@include remit(margin-bottom, 6);
	}
}

%btn, .btn {
	@extend %btn-reset;
	transition: $g-trans;
	@include remit(padding, $hsu 16);
	text-decoration:none;
	position:relative;
	text-align:center;
	display:inline-block;
	min-width:150px;
	font-weight:600;
	color:$white;
	cursor:pointer;
	@include font-rem(16);

	&:last-of-type {
		margin-bottom:0;
	}

  .icon {
    width:20px;
    height:20px;
    @include remit(top, 2);
  }
}

// @function set-notification-text-color($color) {
// 	@if (saturation($color) > 95) {
// 		@return #000000; // Lighter backgorund, return dark color
// 	} @else {
// 		@return #ffffff; // Darker background, return light color
// 	}
// }

$colors: (
	base: ($color-base, $white),
	sub: ($color-sub, $white),
	tri: ($color-tri, $black),
	quad: ($color-quad, $white),
);

// Sass variables compile to a static string; CSS variables are dynamic and inherited
// Loop through the map and set CSS custom properties using Sass variables
@each $button, $color in $colors {
.btn--#{$button} {

		background-color:  nth($color, 1);
		// color: rgba(set-notification-text-color($color), 0.5);
		color: rgba(nth($color, 2), 0.7);

		&:after {
			transition: $g-trans;
			content: '';
			position:absolute;
			bottom:0;
			left:0;
			right:0;
			display:block;
			border-bottom:1px solid nth($color, 1);
		}

		@include hover() {
			animation: pulse 1s;
			// color: set-notification-text-color($color);
			color: nth($color, 2);
			border-color: adjust-hue(nth($color, 1), 25deg);
			box-shadow: 0 0 0 0 adjust-hue(nth($color, 1), 25deg);

			&:after {
				border-bottom:8px solid darken(nth($color, 1), 10%);
			}
		}
	}
}