//==== Example: @include css-triangle ("up", 10px, #fff);
@mixin css-triangle ($direction: "down", $width: 20px, $height: 30px, $color: #000) {
	width: 0;
	height: 0;
	border-left: $width solid #{setTriangleColor($direction, "left", $color)};
	border-right: $width solid #{setTriangleColor($direction, "right", $color)};
	border-bottom: $height solid #{setTriangleColor($direction, "bottom", $color)};
	border-top: $height solid #{setTriangleColor($direction, "top", $color)};
}

//Utility function to return the relevant colour depending on what type of arrow it is
@function setTriangleColor($direction, $side, $color) {

	@if $direction == "left" and $side == "right"
	or  $direction == "right" and $side == "left"
	or $direction == "down" and $side == "top"
	or $direction == "up" and $side == "bottom" {
		@return $color
	} @else {
		@return "transparent";
	}

}