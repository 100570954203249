/* ==========================================================================
	BASE LINK STYLES
========================================================================== */

a {
	// Property, Duration, Animation Type
	transition: $g-trans;
	color:$color-link;
	text-decoration:none;

	@include hover() {
		color:$color-hover;
		text-decoration:none;
	}
}

.header a {
	color:$white;
	text-decoration:none;

	@include hover() {
		color:$black;
	}
}

.teaserAbout__content  a:not([class]), .bg--generic a:not([class]) {
	color:$color-tri;

	@include hover() {
		color:$black;
	}
}

.footerEnd a {
	color:$color-sub;
	text-decoration:none;

	@include hover() {
		color:$color-base;
	}
}

a[href^="tel:"] {
	color:$color-sub;

	.footer &, .header & {
		color:$white;
	}
}

a[href^="mailto:"] {
	color:$color-sub;

	.footer &, .header & {
		color:$white;
	}
}

// /* ==========================================================================
//   MISC LINK STYLES
// ========================================================================== */

// .top {
// 	@extend .icon-arrow-up;
// 	@include font-size(24);
// 	@include remit(padding, $hsu);
// 	@include remit(right, $bsu);
// 	@include remit(bottom, $bsu);
// 	cursor:pointer;
// 	position:fixed;
// 	background-color:$color-sub;
// 	color:$white;
// 	display: none;

// 	&:hover,&:focus,&:active {
// 		background-color:$color-hover;
// 	}
// }