/* ==========================================================================
	PAYMENT TEASER STYLES
========================================================================== */

.teaserPayment {
	@extend %cf;


	&__content {
		@include pad(2);
		max-width:750px;
		margin:auto;

		// @include mq($regular) {
		// 	width:span(16);
		// 	margin:auto;
		// }

		// @include mq($x-large) {
		// 	width:span(13);
		// 	margin:auto;
		// }
	}

	&__cards {
		display:block;
		@include remit(padding-top, $hsu);
		@include remit(margin-bottom, $bsu+$hsu);

		@include mq($small) {
			display:inline-block;
		}
	}

}