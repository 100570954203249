/* ==========================================================================
	LOGO STYLES
========================================================================== */

.bannerLogo {
	display:none;

	@include mq($regular) {
		display:block;
		position:absolute;
		@include remit(right, 20);
		@include remit(top, 20);
		z-index:100;
		width:span(5);
		max-width:200px;
	}
}

.contentLogo {
	display:none;

	@include mq($nav-bp) {
		display:block;
		@include remit(padding-right, $bsu);
		max-width:220px;
		@include span(8 last);
		@include remit(margin-bottom, $bsu);
	}
}
