/* ==========================================================================
	BLOG ABOUT STYLES
========================================================================== */

.teaserAbout {
	@extend %cf;
	background-color:darken($color-base, 10%);

	&__logo {
		background-color:darken($color-base, 10%);
		@include remit(padding, 60);

		@include mq($regular) {
			width:50%;
			display:inline-block;
			vertical-align:middle;
		}
	}

	&__content {

		background-color:$color-base;
		color:$white;
		@include remit(padding, 60);


		@include mq($regular) {
			width:50%;
			display:inline-block;
			vertical-align:middle;
		}

		// a {
		// 	color:$color-tri;
		// }

	}

}