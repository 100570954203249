/* ==========================================================================
  REVIEW STYLES (testimonials page)
========================================================================== */

.reviews--left {
	@include mq($regular) {
		@include span(16);
	}


}

.reviews--right {
	text-align:center;

	@include mq($regular) {
		@include span(7 last);
	}
}


.review {
	@extend %cf;
	// border-bottom:1px solid $lightgrey;
	@include remit(margin-bottom, $g-spacing);

	&__icon {
		width:65px;

		@include mq($regular) {
			float:left;
			@include remit(margin-right, $bsu);
		}
	}

	&__testimonial {
		@include mq($regular) {
			@include span(20);
			margin-right:0;
		}
	}
}