/* ==========================================================================
	ICON STYLES
========================================================================== */

.icon {
	display: inline-block;
	fill: currentColor;
	position:relative;
}

.icon--home {
	width:24px;
	height:23px;

	@include mq($nav-bp) {
		@include remit(top, 4);
	}
}

.icon--star {
	width:24px;
	height:24px;
	color:$lightgrey;
}

.icon--quote {
	width:95px;
	height:73px;
	color:$color-quad;
}

.icon--card {
	width:40px;
	height:25px;
	@include remit(margin-right, 2);
}

.icon--worldpay {
	width:210px;
	height:40px;
	@include remit(margin-right, 20);
	@include remit(top, 4);
}

.icon--tel {
	width:20px;
	height:20px;
	// @include remit(margin-right, 20);
	// @include remit(top, 4);
}

.icon--email {
	width:18px;
	height:13px;
	// @include remit(margin-right, 20);
	// @include remit(top, 4);
}

.icon-error {
	width:18px;
	height:18px;
	@include remit(margin-right, 2);
	@include remit(top, 5);
}

.icon--blog {
	width:95px;
}

.icon--ctaSimple {
	width:80px;
	height:80px;
}