/* ==========================================================================
  BLOCKQUOTE
========================================================================== */

blockquote:before,
q:before {
	content: "";
	padding:20px 0px 0px 0px;
	}

blockquote:after,
q:after {
	content: "";
	float:right; margin-top:-20px;
}

blockquote {
	@include font-size(16);
	color: $turquoise;
	font-style:italic;

	@include remit(padding, $hsu $bsu 0);

	p {
		quotes : "\"" "\"" "'" "'";

		&:before, &:after  {
			@include remit(margin, 0 2);
		}

		&:before {content: open-quote;}
		&:after {content: close-quote;}

	}

	cite {
		@include font-size(16, no, 16);
		display: inline-block;
		color:darken($color-quad, 5%);
		font-weight:600;

		@include mq($small) {
			@include remit(margin, $hsu 6 0 $bsu);
		}


		&:before {
			content: "\2014 \0020";
		}
	}


	.date {
		display: inline-block;
		color:$grey;
		@include font-size(14, no, 14);
	}
}