/* ==========================================================================
	ADVERT STYLES
========================================================================== */

.advert {
	@extend %shadow;
	// background-color:$white;
	// border:12px solid $white;
	@include remit(padding, 12);


	@include mq($regular) {

	}

	&__img {
		position:relative;
		@include remit(margin-bottom, $hsu);
	}

	&__title {
		color:$color-sub;
		@include font-size(30, $hsu, 30*1.2);
		@include remit(padding, 0 $hsu);
	}

	&__strapline {
		position:absolute;
		bottom:0;
		@include remit(padding, $hsu $bsu);
		background-color:rgba($black, 0.6);
		color:$white;
		@include font-size(16, no, 16*1.5);
	}

	&__content {
		@include remit(margin-bottom, $hsu);
		@include remit(padding, 0 $hsu);

		@include mq($small) {
			@include mq($regular, max) {
				display: flex;
				flex-flow: row;
				align-items: center;
			}
		}

		@include mq($large) {
			display: flex;
			flex-flow: row;
			align-items: center;
		}

	}

	&__copy {
		p:last-of-type {
			margin-bottom:0;
		}

		&--withBtn {
			@include remit(margin-right, $bsu);
		}
	}

	&__btn {
		@include remit(margin-top, $hsu);
		min-width:160px;
		align-self:flex-end;
	}
}

// ICON STATMENT
.advert {

	&__statement {
		display: flex;
		flex-flow: row;
		align-items: center;

	}
	&__icon {
		color:$color-base;
		min-width:80px;
		@include remit(margin-right, 20);

		.icon {
			width:80px;
			height:80px;
		}
	}

	&__statementMsg {
		color:$color-base;
		@include font-size(28, no, 28*1.2);
		font-style:italic;
	}
}

.advert--blog {
	@include mq($regular) {
		@include remit(margin-top, -$bsu*2);
	}
}



