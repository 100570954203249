/* ==========================================================================
	CTA SIMPLE STYLES
========================================================================== */

.ctaSimple {
	display:flex;
	flex-flow: row wrap;
	align-content: space-between;
	align-items: flex-start;
	border:1px solid white;
	@extend %no-gutter;
	@include remit(margin-top, $bsu*2);

	@include mq($medium) {
		position:relative;
		left:-2.5vw;
	}

	&__icon {
		@include remit(margin-right, $bsu);
		width: 80px;
	}

	&__content {
		flex: 1 1;
	}
}


.ctaSimple {
	color:$white;
	@include remit(padding, $bsu);


	&__title {
		@include font-size(21,6,21);
	}

	p {
		color:currentColor;
	}

	&--base {
		background-color:$color-base;
	}

	&--sub {
		background-color:$color-sub;
	}

	&--tri {
		background-color:$color-tri;
		color:$black;
	}

	&--quad {
		background-color:$color-quad;
	}
}




