/* ==========================================================================
  TESTIMONIAL STYLES
========================================================================== */

.testimonials {
	@include mq($regular) {
		@include pre(2);
		@include post(2);
	}
}

.testimonials {
	@extend %cf;

	&__freeIndex {
		margin:auto;
		text-align:center;
		width:200px;
		@include remit(margin-bottom, $bsu);

		@include mq($regular) {
			float:left;
			@include post(1);
		}
	}

	&__icon {
		width:95px;

		@include mq($regular) {
			float:left;
		}
	}

	&__testimonials {
		@include remit(margin-bottom, 36);

		@include mq($large) {
			@include span(15);
		}
	}
}


.rating {
	@include remit(padding-top, 20);
	@include remit(padding-left, 20);
}

.FIWidgetContainer {
	margin:auto;
	text-align:center;
}