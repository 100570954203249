/* ==========================================================================
	EXPANDING BLOCK STYLES
========================================================================== */

.js-expand-block__body {
  display:none;

  &.is-active {
    display:block;
  }
}


.expandingBlock {
	@include remit(margin-bottom, $bsu);
	border-bottom:1px solid $lightgrey;

	&:first-of-type() {
		@include remit(margin-top, $bsu*2);
	}

	&:last-of-type() {
		@include remit(margin-bottom, $bsu*2);
	}

	&__title {
    font-family:$font-body;
    position:relative;
		font-weight:400;
		color:$color-base !important;
		@include font-size(17, $hsu, 17*1.2);
		@include remit(padding, 0 $hsu $hsu);
		cursor:pointer;
    @include remit(padding-left, 30);

    &:before {
      content: '';
      position:absolute;
      dislay:inline-block;
      @include remit(left, 5);
      @include remit(top, 6);
      @include css-triangle(down, 8px, 8px, $color-base);
    }

    &.is-active {
      &:before {
         @include remit(left, 12);
         @include remit(top, 3);
         @include css-triangle(right, 8px, 8px, $color-base);
      }
    }
	}

	&__content {
		@include fadeInUp($fill:none);
		@include remit(padding, 0 $hsu $hsu);

	}
}
