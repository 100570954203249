/*---------------------------------------------------
    COLOUR STYLES v1.1
  ---------------------------------------------------
    A set of common SASS colour styles by Terry Upton (Amasci Creative Limited)
	These should be unique for every project.
-----------------------------------------------------*/

/* ==========================================================================
   COLOUR STYLES
========================================================================== */

.color--base 		{color:$color-base !important;}
.color--sub 			{color:$color-sub !important;}
.required 			{color:$color-base; font-weight:bold;}

.color-error 		{color:$color-error !important;}
// .success-color		{color:$success-color;}



@each $section, $color in $sections {
	.bg--#{$section} {
		background-color:$color;

		@include hover() {
			background-color:shade($color, 10%);
		}
	}

	// a[href=''] .bg--#{$section} {
	// 	@include hover() {
	// 		background-color:shade($color, 10%);
	// 	}
	// }

	.color--section {
		color:$color;
	}
}


@each $service, $color in $services {
	.bg--#{$service} {
		background-color:$color;
		transition:$g-trans;

		@include hover() {
			background-color:shade($color, 10%);
		}
	}

	// .color--section {
	// 	color:$color;
	// }
}