/* ==========================================================================
	GET STARTED FORM STYLES
========================================================================== */

.getStarted__form {
	transition:$g-trans;
	@extend %padding-container;
	clear:both;
	max-width:800px;
	overflow:hidden;
	@include fadeOutUp();
	max-height: 0;

	@include mq($regular) {
		position:relative;
		@include remit(top, -36);
		margin-left:42%;
		@include remit(padding-left, $bsu);
	}

	.no-js & {
		display:block;
	}

	&.is-active {
		@include fadeInDown();
		height:auto;
		 max-height:999px;
	}
}