/* ==========================================================================
	SOCIAL ICON STYLES
========================================================================== */
.icon-social {

	width: 32px;
	height: 32px;
	fill: currentColor;

	@include hover() {
		transition:all .40s ease-in-out;
		transform: scale(1.4);
		z-index:10;
	}

	.footer & {
		fill:$white;

		@include hover {
			fill:$white;
		}
	}
}

@each $social-media, $color in $social-media {
.icon-#{$social-media} {
		fill:$color;
	}
}

.icon-email-share {
	transition:$g-trans;
	color:$color-text;
}


.social-list {
	@include remit(margin-bottom, $hsu);

	li {
		list-style:none;
		display:inline-block;
		@include remit(margin-right, 8);
	}
}

.socialShare__title {
	color:$black;
	@include font-size(18, 10, 18*1.2);
}