/* ==========================================================================
	HERO BANNER STYLES
========================================================================== */

.slick-slider, .slickHeroBanner, .heroBannerNav { display: none; }
.slick-slider.slick-initialized, .slickHeroBanner.slick-initialized, .heroBannerNav.slick-initialized { display: block; }

.banner {
	@extend %cf;
	position:relative;
}

$map-services: (
	gutters: 0
);

@include with-layout($map-services) {
	.heroBanner {
		@extend %cf;

		&__item {
			@extend %cf;
			position:relative;
		}

		&__img, &__content {
			position:relative;

			@include mq($regular) {
				@include gallery(12);
			}
		}

		&__caption {
			color:$white;
			position:absolute;;
			bottom:5%;
			left:5%;
			max-width:70%;
			background-color:rgba($black, 0.6);
			font-family:$font-header;
			@include font-size(18, no, 18*1.2);
			@include remit(padding, $hsu $bsu);
			font-style:italic;
			z-index:100;

			@include mq($small) {
				width:auto;
				@include font-size(22, no, 22*1.2);
			}
		}

		&__img img {
			@include object-fit(cover, center);
			// object-fit: cover;
			// object-position: 100% 100%;
			height:45vh;
			width: 100%;

			@include mq($medium) {
				height: 100%;
			}
		}

		@include mq($regular) {
			&__img {
				position: absolute;
				top: 0; bottom: 0; left: 0;
			}
		}

		&__content {
			color:$white;
			@include remit(padding, $bsu);

			@include mq($regular) {
				@include remit(padding, 100  $bsu 60  $bsu);
			}
		}

		&__icon {
			@include remit(margin-bottom, $hsu);
			text-align:center;

			@include mq($regular) {
				text-align:left;
				@include span(2 of 12);
				@include remit(margin-top, -30);

				svg {
					max-width:80%;
				}
			}
		}

		&__copy {
			@include mq($regular) {
				@include span(10 of 12);

				&--no-icon {
					@include span(12 of 12);
					@include pad(1);
				}
			}
		}

		&__title {
			@include font-size(36);

			&--with-icon {
				text-align:center;

				@include mq($regular) {
					text-align:left;
				}
			}
		}

		&__btn {
			display:inline-block;
		}
	}


	.heroBannerNav {
		border-top:1px solid $white;
		margin-top:-10px;

		@include mq($regular) {
			border-top:0;
		}

		&__item {
			.slick-current & {
				&:after {
					border-bottom:20px solid rgba($black, 0.2);
				}
			}
		}
	}
}



















