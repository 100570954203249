/* ==========================================================================
	NAVIGATION STYLES
========================================================================== */

.nav {
	li {
		list-style:none;
		text-align:center;

		@include mq($nav-bp) {
			display:inline-block;
		}
	}

	a {
		display:block;
	}
}


/* ==========================================================================
	 MAIN NAV
========================================================================== */

.nav--main {

	ul {
		@extend %cf;
	}

	li {
		@include font-size(16, $hsu, 24);
		width:span(12);
		display:inline-block;
		@include fadeInDown($duration:0.5s, $delay:0.5s, $fill:both);
		@extend %animate-nav-items;


		@include mq($nav-bp) {
			width:auto;
			@include font-size(14, 6);

			&:not(:last-of-type) {
				@include remit(margin-right, 20);
			}

			@include mq($large) {
				@include font-size(16,no);

				&:not(:last-of-type) {
					@include remit(margin-right, 40);
				}
			}
		}

		.header__top.is-active & {
			@include fadeInDown($duration:0.5s, $delay:0.1s, $fill:both);
			@extend %animate-nav-items;
		}
	}

	.home {
		width:span(12);
		display:inline-block;

		@include mq($nav-bp) {
			width:auto;
		}
	}

	// a {

	// 	&:hover,&:focus,&:active {
	// 		color:$color-hover;
	// 		text-decoration:none;
	// 	}
	// }

	.current a {
		color:$black;
	}
}

/* ==========================================================================
	 FOOTER NAV
========================================================================== */

.nav--footer {

	li {
		text-align:center;
		@include font-size($footer-font-size, 6);
		color:$white;

		@include gallery(12);
		@include remit(padding, 0 3);

		@include mq($small) {
			@include mq($regular, max) {
				@include gallery(8);
				@include remit(padding, 0 6);
			}
		}

		@include mq($x-large) {
			@include gallery(8);
			@include remit(padding, 0 6);
		}
	}



	a {
		color:$white;

		@include hover() {
			color:$color-hover;
			text-decoration:none;
		}
	}
}

/* ==========================================================================
   SIDEBAR  NAV
========================================================================== */

.nav--sidebar {

  li {
    list-style:none;
    @include remit(margin-bottom, $hsu);
    @include remit(margin-left, 36);

    &:first-child {
      @include remit(margin-top, $bsu);
    }
  }
}