/* ==========================================================================
	HEADER BTTON STYLES
========================================================================== */
.header__btns {
	width:span(12);
	display:inline-block;
	text-align:right;

	@include mq($nav-bp) {
		display:none;
	}
}

.header__btn {
	text-align:center;
	position:relative;
	display:inline-block;
	vertical-align:middle;


	@include mq($small) {
		@include remit(margin-left, $hsu);
	}

	.header__btn-icon {
		color:$white;
		@extend %btn-reset;
	}
}
